import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import { useJobOpeningPromotedForm } from 'dashboard/jobOpening/contexts/JobOpeningPromotedFormContext';
import { useState, useRef, useEffect } from 'react';
// modified from https://robkendal.co.uk/blog/how-to-build-a-multi-image-carousel-in-react-and-tailwind

interface BannerCarouselProps {
    data: ATSBanner[];
    /**
     * the interval of banner autoslide in seconds
     */
    delay: number;
    withJSXCarousel?: boolean;
    indicatorColor?: string;
    circleIndicator?: boolean;
    indicatorPosition?: string;
    indicatorClasses?: string;
    bannerFullWidth?: boolean;
    tracker?: {
        domainLabel: string;
        domain: TrackingDomain;
    };
    aspectRatio?: `aspect-[${string}]`;
}

const BannerCarousel = ({
    data,
    delay,
    withJSXCarousel,
    indicatorColor,
    indicatorPosition,
    indicatorClasses,
    circleIndicator,
    bannerFullWidth = false,
    aspectRatio = 'aspect-[4/1]',
    tracker
}: BannerCarouselProps): JSX.Element => {
    const maxScrollWidth = useRef(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const carousel = useRef<HTMLDivElement>(null);
    const track = useLazyAuthTracking(tracker?.domain);

    const { setFromBanner } = useJobOpeningPromotedForm();

    if (data.length == 0) return <></>;

    useEffect(() => {
        if (carousel !== null && carousel.current !== null) {
            carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
        }
    }, [currentIndex]);

    useEffect(() => {
        if (data.length > 1) {
            const interval = setInterval(() => {
                // check if we're not on the last slide, if so then go to next slide, else go to beginning
                // scrollLeft is added by 32 to accomodate for gap width
                if (
                    carousel.current !== null &&
                    carousel.current.scrollLeft + 32 < maxScrollWidth.current
                ) {
                    setCurrentIndex((prevState) => prevState + 1);
                } else setCurrentIndex(0);
            }, delay * 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    useEffect(() => {
        maxScrollWidth.current = carousel.current
            ? carousel.current.scrollWidth - carousel.current.offsetWidth
            : 0;
    }, []);

    const onClickIndicator = (idx: number): void => {
        setCurrentIndex(idx);
        track.event(`Clicks ${tracker?.domainLabel} Carousel Indicator Banner Carousel`, {
            Position: idx + 1
        });
    };

    return (
        <div className="carousel relative w-full h-full">
            <div
                className={`flex justify-${indicatorPosition} items-end gap-2 absolute inset-0 p-4 ${
                    indicatorClasses ?? ''
                }`}>
                {data.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => onClickIndicator(index)}
                        className={`z-[1] h-2 rounded-full transition hidden lg:block ${
                            currentIndex === index ? indicatorColor ?? 'bg-sky-600' : 'bg-sky-50'
                        } ${circleIndicator ? 'w-2' : 'w-5 sm:w-6'}`}></button>
                ))}
            </div>
            <div
                className={`relative w-full h-full overflow-hidden ${
                    !withJSXCarousel && aspectRatio
                }`}>
                <div
                    ref={carousel}
                    className="carousel-container h-full relative flex gap-1 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x">
                    {data.map((banner, index) => {
                        return (
                            <div
                                key={index}
                                className={`carousel-item relative h-full snap-start ${
                                    !withJSXCarousel && aspectRatio
                                } ${bannerFullWidth && 'w-full flex-shrink-0'}`}>
                                {banner.banner_jsx ? (
                                    banner.banner_jsx
                                ) : banner.target_url !=
                                  'https://client.lumina.mba/dashboard/opening?banner=promoted' ? (
                                    <a
                                        onClick={() => {
                                            track.event('Click ATS Banner', {
                                                BannerID: banner?.id,
                                                BannerTitle: banner?.title
                                            });
                                        }}
                                        key={banner.target_url}
                                        href={banner.target_url}
                                        target="blank"
                                        className={`h-full ${aspectRatio} block bg-origin-padding bg-left-top bg-cover bg-no-repeat -z-10`}
                                        style={{
                                            backgroundImage: `url(${banner.banner_url || ''})`
                                        }}>
                                        <img
                                            src={banner.banner_url || ''}
                                            alt="hey"
                                            className={`h-full ${aspectRatio} hidden`}
                                        />
                                    </a>
                                ) : (
                                    <button
                                        onClick={() => {
                                            setFromBanner(true);
                                            track.event('Click ATS Banner', {
                                                BannerID: banner?.id,
                                                BannerTitle: banner?.title
                                            });
                                        }}
                                        id="promoted-banner"
                                        className="h-full aspect-[13/3] block bg-origin-padding bg-left-top bg-cover bg-no-repeat -z-10"
                                        style={{
                                            backgroundImage: `url(${banner.banner_url || ''})`
                                        }}>
                                        <img
                                            src={banner.banner_url || ''}
                                            alt="hey"
                                            className={`h-full ${aspectRatio} hidden`}
                                        />
                                    </button>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default BannerCarousel;
