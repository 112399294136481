import useCompanyUser from 'authentication/hooks/useCompanyUser';
import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { Control, FieldErrors, useForm, UseFormHandleSubmit, useWatch } from 'react-hook-form';
import {
    useGetJobOpeningSimplifiedQuery,
    useGetJobPromotionPricingPlansQuery,
    useCreatePurchaseJobPromotionMutation,
    useCreatePurchaseJobPromotionQuotaBasedMutation
} from '../redux/api/jobOpeningApi';
import { JOB_PROMOTION_DEFAULT_PRICING_PLAN } from '../constant';
import { useGetSubscriptionDetailQuery } from 'dashboard/profile/redux/api/profileApi';

type JobOpeningPromotedFormContextType = {
    control: Control<PurchaseJobPromotionInputData, any>;
    handleSubmit: UseFormHandleSubmit<PurchaseJobPromotionInputData>;
    isJobOpeningPromotedOpen: boolean;
    isSuccessModalOpen: boolean;
    isJobOpeningPromotedQuotaBasedOpen: boolean;
    setIsSuccessModalOpen: Dispatch<SetStateAction<boolean>>;
    setIsJobOpeningPromotedOpen: Dispatch<SetStateAction<boolean>>;
    setIsJobOpeningPromotedQuotaBasedOpen: Dispatch<SetStateAction<boolean>>;
    jobOpeningId: string;
    setJobOpeningId: Dispatch<SetStateAction<string>>;
    jobOpeningData: JobOpeningSimplified | undefined;
    isLoading: boolean;
    errors: FieldErrors<PurchaseJobPromotionInputData>;
    onPromote: () => void;
    onSubmit: (data: PurchaseJobPromotionInputData) => void;
    onSubmitQuotaBased: (data: JobPromotionQuotaBasedInputData) => void;
    setFromBanner: Dispatch<SetStateAction<boolean>>;
    fromBanner: boolean;
    jobPromotionPricingPlans: ResponseData<JobPromotionPricingPlan> | undefined;
    totalPrice: number;
    onLoadingJobPromoted: boolean;
    pricingPlansTitle: string;
};

const JobOpeningPromotedFormContext = createContext<JobOpeningPromotedFormContextType>(
    {} as JobOpeningPromotedFormContextType
);

export function JobOpeningPromotedFormProvider({ children }: { children: ReactNode }) {
    const track = useLazyAuthTracking('PROMOTED JOB');
    const { isACompanyUser } = useCompanyUser();
    const { data: subscription } = useGetSubscriptionDetailQuery();

    const { data: jobPromotionPricingPlans } = useGetJobPromotionPricingPlansQuery(undefined, {
        skip: !isACompanyUser
    });

    const [createJobOpeningPromoted, { isSuccess, isLoading: onLoadingJobPromoted }] =
        useCreatePurchaseJobPromotionMutation();

    const [
        createJobOpeningPromotedQuotaBased,
        { isSuccess: isSuccessQuotaBased, isLoading: onLoadingJobPromotedQuotaBased }
    ] = useCreatePurchaseJobPromotionQuotaBasedMutation();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm<PurchaseJobPromotionInputData>({
        defaultValues: {
            job_opening_id: '',
            pricing_package_id: ''
        }
    });

    const [isJobOpeningPromotedOpen, setIsJobOpeningPromotedOpen] = useState<boolean>(false);
    const [isJobOpeningPromotedQuotaBasedOpen, setIsJobOpeningPromotedQuotaBasedOpen] =
        useState<boolean>(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
    const [jobOpeningId, setJobOpeningId] = useState<string>('');
    const [fromBanner, setFromBanner] = useState<boolean>(false);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [pricingPlansTitle, setPricingPlansTitle] = useState<string>('');

    const pickedPricingPlans = useWatch({
        control,
        name: 'pricing_package_id'
    });

    useEffect(() => {
        if (isJobOpeningPromotedOpen && jobPromotionPricingPlans?.data) {
            const defaultPlan = jobPromotionPricingPlans.data.find(
                (pricing_plan) => pricing_plan.title === JOB_PROMOTION_DEFAULT_PRICING_PLAN
            );

            if (defaultPlan) {
                setValue('pricing_package_id', defaultPlan.id);
            }
        }
    }, [jobPromotionPricingPlans, isJobOpeningPromotedOpen]);

    useEffect(() => {
        if (pickedPricingPlans) {
            setPricingPlansTitle(
                jobPromotionPricingPlans?.data.find((plan) => plan.id === pickedPricingPlans)
                    ?.title || ''
            );
        }
    }, [pickedPricingPlans]);

    useEffect(() => {
        setTotalPrice(
            Number(
                jobPromotionPricingPlans?.data.find((plan) => plan.id === pickedPricingPlans)
                    ?.price || 0
            )
        );
    }, [pickedPricingPlans]);

    const { data: jobOpeningData, isFetching: isLoading } = useGetJobOpeningSimplifiedQuery(
        jobOpeningId,
        {
            skip: !jobOpeningId
        }
    );

    useEffect(() => {
        if (isSuccessModalOpen) {
            setIsJobOpeningPromotedOpen(false);
        }
    }, [isSuccessModalOpen]);

    useEffect(() => {
        if (!isJobOpeningPromotedOpen) {
            if (fromBanner && !isSuccessModalOpen) setFromBanner(false);
            setValue('job_opening_id', '');
            setValue('pricing_package_id', '');
        }
    }, [isJobOpeningPromotedOpen]);

    useEffect(() => {
        if (isSuccess) {
            setIsJobOpeningPromotedOpen(false);
            setIsSuccessModalOpen(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccessQuotaBased) {
            setIsJobOpeningPromotedQuotaBasedOpen(false);
            setIsSuccessModalOpen(true);
        }
    }, [isSuccessQuotaBased]);

    useEffect(() => {
        if (fromBanner) {
            setIsJobOpeningPromotedOpen(true);
        }
    }, [fromBanner]);

    useEffect(() => {
        if (!isSuccessModalOpen && fromBanner) setFromBanner(false);
    }, [isSuccessModalOpen]);

    const onPromote = (): void => {
        if (subscription && subscription.job_promotion_quota) {
            if (subscription.job_promotion_quota.used < subscription.job_promotion_quota.total) {
                setIsJobOpeningPromotedQuotaBasedOpen(true);
                return;
            }
        }

        setIsJobOpeningPromotedOpen(true);
    };

    const onSubmit: (data: PurchaseJobPromotionInputData) => void = async (
        data: PurchaseJobPromotionInputData
    ) => {
        if (!onLoadingJobPromoted) {
            if (jobOpeningId) data['job_opening_id'] = jobOpeningId;

            await createJobOpeningPromoted({
                ...data
            });

            const eventName = 'Purchase Job Promotion';
            track.event(eventName, {
                Package: pricingPlansTitle,
                Price: totalPrice
            });
            track.moengageEvent(eventName);
        }
    };

    const onSubmitQuotaBased: (data: JobPromotionQuotaBasedInputData) => void = async (
        data: JobPromotionQuotaBasedInputData
    ) => {
        if (!onLoadingJobPromotedQuotaBased) {
            await createJobOpeningPromotedQuotaBased(data);

            const eventName = 'Promote Job with Quota';
            track.event(eventName);
            track.moengageEvent(eventName);
        }
    };

    const memoedValue = useMemo(
        () => ({
            control,
            handleSubmit,
            isJobOpeningPromotedOpen,
            isJobOpeningPromotedQuotaBasedOpen,
            isSuccessModalOpen,
            setIsSuccessModalOpen,
            setIsJobOpeningPromotedQuotaBasedOpen,
            setIsJobOpeningPromotedOpen,
            jobOpeningId,
            setJobOpeningId,
            jobOpeningData,
            isLoading,
            errors,
            onSubmit,
            onSubmitQuotaBased,
            onPromote,
            setFromBanner,
            fromBanner,
            jobPromotionPricingPlans,
            totalPrice,
            onLoadingJobPromoted,
            pricingPlansTitle
        }),
        [
            control,
            isJobOpeningPromotedOpen,
            jobOpeningId,
            jobOpeningData,
            isSuccessModalOpen,
            isJobOpeningPromotedQuotaBasedOpen,
            fromBanner,
            errors,
            jobPromotionPricingPlans,
            totalPrice,
            onLoadingJobPromoted,
            pricingPlansTitle
        ]
    );

    return (
        <JobOpeningPromotedFormContext.Provider value={memoedValue}>
            {children}
        </JobOpeningPromotedFormContext.Provider>
    );
}

export const useJobOpeningPromotedForm = () => {
    return useContext(JobOpeningPromotedFormContext);
};

export default JobOpeningPromotedFormContext;
